<div>
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></div>
    <p-calendar
        #calendar
        (onClose)="onClose()"
        [dateFormat]="dateFormat"
        [formControl]="control"
        [styleClass]="styleClass"
        autocomplete="off"
        [placeholder]="placeholder || ''"
        [showIcon]="true"
        [ngClass]="{
            'ng-invalid ng-dirty': control.touched && control.invalid
        }"
        [selectionMode]="selectionMode"
        (onSelect)="onSelect()"
        [showButtonBar]="true"
        [firstDayOfWeek]="1"
        [showClear]="false"
        (onClearClick)="onClear()"
        [appendTo]="appendTo"
    />
    <ng-container>
        <app-form-error *ngIf="control.touched && showErrors" [errors]="control.errors" />
    </ng-container>
    <span #span tabindex="-1"></span>
</div>
